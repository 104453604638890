var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pano-button-container ellipsis",class:{
  'pano-button-small':_vm.size=='small',
'pano-button-medium':_vm.size=='medium',
'pano-button-large':_vm.size=='large',
'pano-button-default':_vm.type=='default',
'pano-button-del':_vm.type=='del'
},on:{"click":function($event){return _vm.click()}}},[_vm._v(" "+_vm._s(_vm.name)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }