<template>
  <div class="pano-container">
    <div id="webapp-view-pano" class="webapp-pano-view-container">
      <noscript>
        <table style="width:100%;height: 100%;">
          <tr style="vertical-align:middle;">
            <td>
              <div style="text-align:center;">
                ERROR:
                <br />
                <br />Javascript not activated
                <br />
                <br />
              </div>
            </td>
          </tr>
        </table>
      </noscript>
    </div>
    <Jump @showSide="showSideHandle" :name="name"></Jump>
    <van-popup v-model="show">
      <video v-if="show" :src="videoUrl" controls autoplay style="width: 90vw; height: 50vh;background: #000;"></video>
    </van-popup>
    <van-popup v-model="showHtml">
      <div v-if="showHtml" v-html="htmlContent" class="html_wrap"></div>
    </van-popup>
    <van-popup v-model="showProject">
      <div class="html_wrap" style="height:80vh;">
        <div class="item">
          <div class="lab">项目名称：</div>
          <div class="con">中国海油深海能源开发总指挥部基地项目01地块</div>
        </div>
        <div class="item">
          <div class="lab">建设单位：</div>
          <div class="con">中海石油(中国)有限公司海南分公司</div>
        </div>
        <div class="item">
          <div class="lab">设计单位：</div>
          <div class="con">北京市建筑设计研究院有限公司</div>
        </div>
        <div class="item">
          <div class="lab">施工单位：</div>
          <div class="con">中化学建设(海南)有限公司</div>
        </div>
        <div class="item">
          <div class="lab">监理单位：</div>
          <div class="con">成都衡泰工程管理有限责任公司</div>
        </div>
        <div class="item">
          <div class="lab">勘察单位：</div>
          <div class="con">深圳市勘察测绘院(集团)有限公司</div>
        </div>
        <div class="item">
          <div class="lab">项目概况：</div>
          <div class="con">
            中国海油深海能源开发总指挥部基地项目位于海南省海口市西海岸新区南片区长秀大道与海涛西路交接处东南角，项目总造价112877.6万元，总建筑面积175090平米。项目施工方单位为中化学建设(海南)有限公司。</div>
        </div>
        <div class="item">
          <div class="lab">项目进度：</div>
          <div class="con">目前处于主体阶段，其中T1-2塔楼已结顶；T1-1塔楼核心筒已结顶，钢结构27层。</div>
        </div>
        <div class="img">
          <img :src="require('../../../assets/projectdetail2.png')" alt />
        </div>
      </div>
    </van-popup>
    <van-popup v-model="show">
      <video v-if="show" :src="videoUrl" controls autoplay style="width: 90vw; height: 50vh;background: #000;"></video>
    </van-popup>
    <van-popup v-model="showHtml">
      <div v-if="showHtml" v-html="htmlContent" class="html_wrap"></div>
    </van-popup>
    <van-popup v-model="showcg">
      <div class="html_wrap" style="height:80vh;">
        <iframe v-if="showcg" style="width: 100%;height: 100%;" src="https://zhgd.zjbdsc.com/apsPublic/https/projectCover/construct.html" frameborder="0"></iframe>
      </div>
    </van-popup>
    <van-popup v-model="showbd">
      <div class="html_wrap" style="height:80vh;">
        <iframe v-if="showbd" style="width: 100%;height: 100%;" src="https://zhgd.zjbdsc.com/apsPublic/https/projectCover/introduce.html" frameborder="0"></iframe>
      </div>
    </van-popup>
    <van-popup v-model="showsc">
      <div class="html_wrap" style="height:80vh;">
        <iframe  style="width: 100%;height: 100%;" src="https://zhgd.zjbdsc.com/apsPublic/https/pdf/web/viewer.html?file=https://zhgd.zjbdsc.com/apsPublic/https/%E8%A7%82%E6%91%A9%E6%89%8B%E5%86%8C.pdf" frameborder="0"></iframe>
      </div>
    </van-popup>
    <van-popup v-model="showMeeting">
      <!-- <div class="html_wrap meeting_wrap" style="height:50vh;">
        <div class="title">上午：全国工程质量数字化监管现场会</div>
        <div class="sec_title">第一阶段: 现场会议 (晓风印月项目)</div>
        <div class="item">
          <div class="lab">8:30</div>
          <div class="con">酒店门口集中出发</div>
        </div>
        <div class="item">
          <div class="lab">8:30—9:00：</div>
          <div class="con">现场滚动播放台州建设工作宣传片</div>
        </div>
        <div class="item">
          <div class="lab">9:00—9:05：</div>
          <div class="con">主持人宣布会议开始，介绍与会领导</div>
        </div>
        <div class="item">
          <div class="lab">9:05—9:10：</div>
          <div class="con">浙江省政府领导致欢迎辞</div>
        </div>
        <div class="item">
          <div class="lab">9:10—9:15：</div>
          <div class="con">视频播放</div>
        </div>
        <div class="item">
          <div class="lab">9:15—9:20：</div>
          <div class="con">台州市政府汇报工程质量数字化监管情况</div>
        </div>
        <div class="item">
          <div class="lab">9:20—9:35：</div>
          <div class="con">省市交流发言</div>
        </div>
        <div class="item">
          <div class="lab">9:35—9:55：</div>
          <div class="con">现场滚动播放台州建设工作宣传片</div>
        </div>
        <div class="sec_title">第二阶段: 现场观摩 (晓风印月项目)</div>
        <div class="item">
          <div class="lab">10:00—11:30：</div>
          <div class="con">与会人员参观宣传展示区</div>
        </div>
        <div class="item">
          <div class="lab">11:30—12:00：</div>
          <div class="con">返程回住宿酒店</div>
        </div>
        <div class="item">
          <div class="lab">12:00：</div>
          <div class="con">酒店用午餐 (自助餐)</div>
        </div>
        <div class="title">下午: 全国工程质量和检测工作调研</div>
        <div class="sec_title">第一阶段: 全国工程质量和检测工作调研</div>
        <div class="item">
          <div class="lab">14:30—15:30：</div>
          <div class="con">工程质量和检测工作座谈</div>
        </div>
        <div class="item">
          <div class="lab">15:30—15:45：</div>
          <div class="con">茶歇</div>
        </div>
        <div class="sec_title">第二阶段: 全国工程质量管理论坛</div>
        <div class="item">
          <div class="lab">15:45—16:30：</div>
          <div class="con">三位专家作主旨演讲 (由建设部邀请)</div>
        </div>
        <div class="item">
          <div class="lab">16:30</div>
          <div class="con">会议结束</div>
        </div>
      </div>-->
      <div class="html_wrap meeting_wrap" style="height:50vh;">
        <div class="title">一、会议名称</div>
        <div class="sec_title">全国工程质量数字化监管现场会</div>
        <div class="title">二、会议时间</div>
        <div class="sec_title">2023年6月15日</div>
        <div class="title">三、会议组织</div>
        <div class="sec_title">主办单位：住房和城乡建设部</div>
        <div class="sec_title">承办单位：浙江省住房和城乡建设厅、台州市人民政府</div>
        <div class="title">四、会议地点</div>
        <div class="sec_title">（一）现场观摩地点（上午8:30-11:30）</div>

        <div class="item">
          <div class="con">1.晓风印月项目；</div>
        </div>
        <div class="item">
          <div class="con">2.葭沚老街项目。</div>
        </div>
        <div class="sec_title">（二）工程质量数字化监管大会（下午14:00-15:00，方远台州大饭店一楼1号会议室）</div>

        <div class="item">
          <div class="con">1.浙江省政府领导致辞；</div>
        </div>
        <div class="item">
          <div class="con">2.视频播放：住房和城乡建设部建筑工程质量安全综合服务平台试点成果；</div>
        </div>
        <div class="item">
          <div class="con">3.浙江省住房和城乡建设厅发言；</div>
        </div>
        <div class="item">
          <div class="con">4.台州市人民政府介绍工程质量数字化监管经验；</div>
        </div>
        <div class="item">
          <div class="con">5.湖北省住房和城乡建设厅、成都市住房和城乡建设局交流发言；</div>
        </div>
        <div class="item">
          <div class="con">6.住房和城乡建设部领导讲话。</div>
        </div>
        <div class="sec_title">（三）调研座谈（下午15:10-17:10）</div>
        <div class="item">
          <div class="con">1.调研座谈（下午15:10开始，方远台州大饭店三楼2号会议室）。</div>
        </div>
        <div class="item">
          <div class="con">2.主题论坛（下午15:10开始，方远台州大饭店一楼1号会议室）。</div>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="showVideos" round position="bottom">
      <div class="video_list_wrap">
        <div class="video_title">视频查看</div>
        <div class="video_sec_title">10大系统视频介绍</div>
        <div class="video_content">
          <div class="video_item" @click="openVideo(item)" v-for="(item, index) in videoList" :key="index">
            {{ item.name }}.mp4
            <div class="video_img"></div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- <div class="side_wrap"> -->
    <div class="side_wrap" v-if="showSide">
      <!-- <div class="item" @click="enterHouse">
        <div class="icon">
          <img :src="require('../../../assets/分组 5.png')" alt />
        </div>
        <div class="label">进入场馆</div>
      </div> -->
      <div class="item" @click="showProject = true">
        <div class="icon">
          <img :src="require('../../../assets/画板 3.png')" alt />
        </div>
        <div class="label">项目概况</div>
      </div>
      <div class="item" @click="showcg = true">
        <div class="icon">
          <img :src="require('../../../assets/分组 2.png')" alt />
        </div>
        <div class="label">建设成果</div>
      </div>
      <div class="item" @click="showsc = true">
        <div class="icon">
          <img style="width:20px" :src="require('../../../assets/zghx.png')" alt />
        </div>
        <div class="label">观摩手册</div>
      </div>
      <!-- <div class="item" @click="showVideos=true">
        <div class="icon">
          <img :src="require('../../../assets/分组 1.png')" alt />
        </div>
        <div class="label">视频介绍</div>
      </div> -->

      <div class="item" @click="showbd = true">
        <div class="icon">
          <img style="width:20px" :src="require('../../../assets/bd.png')" alt />
        </div>
        <div class="label">资料下载</div>
      </div>
      <!-- <div class="item" @click="gotoExample">
        <div class="icon">
          <img :src="require('../../../assets/画板 3.png')" alt />
        </div>
        <div class="label">工地案例</div>
      </div> -->
    </div>
    <van-action-sheet cancel-text="取消" v-model="showPhone" :actions="actions" @select="onSelect" />
    <!-- <ToolScenes></ToolScenes> -->
  </div>
</template>
<script>
import Jump from "./components/jump";
import ToolScenes from "./components/tool-scenes";
import { myPanoProject, transfer } from "api/pano/pano";
import { mapActions } from "vuex";

export default {
  components: {
    ToolScenes,
    Jump
  },
  props: {
    name: ""
  },
  mounted() {
    window.hotspotGoScene = this.hotspotGoScene;
    document.title = this.name;
    myPanoProject(this.$route.params.id).then(o => {
      this.project = {
        cover: o.cover,
        name: o.name,
        id: o.id,
        description: o.description,
        xml: o.xml
      };
      this.projectScenes = [...o.scenes];
      let settings = {};
      settings["onstart"] = `loadxml('${o.xml}')`;
      //拿到projectScenes里的数据 并根据videoList里面的videoUrl去重videoList
      this.projectScenes.forEach(e => {
        e.hotspots.forEach(e2 => {
          if (e2.type == 4 && !e2.name.includes('讲解')) {
            if (!this.videoList.find(e3 => e3.videoUrl == e2.videoUrl)) {
              this.videoList.push(e2);
            }
          }
        });
      });
      console.log(JSON.parse(JSON.stringify(this.videoList)));

      embedpano({
        xml: "",
        target: "webapp-view-pano",
        html5: "auto",
        mobilescale: 1.0,
        passQueryParameters: true,
        vars: settings,
        onready: this.onready
      });
    });
  },
  methods: {
    ...mapActions("webappPanoViewModule", ["initKrpano", "onHotspotGoScene"]),
    ...mapActions("webappEditModule", [
      "onHotspotToggle",
      "onSlideClickHandle",
      "lookToHotspot"
    ]),
    gotoExample() {
      // location.href=this.$url("webapp/panoView/bd866602302016978949?name=台州市一江山大道北侧、台州大道东侧地块建设项目&phone=17681511611")
      // location.href=this.$url("webapp/panoView2/bd873555313964482565?name=仙居理想之城")
      location.href = location.origin + "/webapp/panoView2/bd873555313964482565?name=仙居理想之城"
    },
    enterHouse() {
      var obj = this.projectScenes[0].hotspots[0]
      this.onHotspotGoScene({
        sceneId: obj.sceneId,
        toSceneId: obj.toSceneId,
        hotspotId: obj.id
      });
    },
    onSelect(item) {
      var phone = item.name.split('：')[1]
      this.showPhone = false;
      wx.miniProgram.navigateTo({
        url: `/pages/phone/phone?phone=${phone}`
      });
    },
    openVideo(obj) {
      this.showVideos = false;
      transfer({
        phone: this.$route.query.phone,
        type: obj.type,
        typeName: obj.name
      });
      this.videoUrl = "//" + obj.videoUrl;
      this.show = true;
    },
    openPosition() {
      var latitude = 28.632335;
      var longitude = 121.397322;
      var name = "绿城城投·晓风印月";
      wx.miniProgram.navigateTo({
        url: `/pages/location/location?latitude=${latitude}&longitude=${longitude}&name=${name}`
      });
    },
    showSideHandle() {
      this.showSide = !this.showSide;
    },
    onready(kp) {
      this.initKrpano({
        kp: kp,
        project: this.project,
        scenes: this.projectScenes
      });
    },
    hotspotGoScene(sceneId, toSceneId, hotspotId) {
      var sceneObj = this.projectScenes.find(o => o.id == sceneId);
      var obj = sceneObj.hotspots.find(o => o.id == hotspotId);
      transfer({
        phone: this.$route.query.phone,
        type: obj.type,
        typeName: obj.name
      });
      if (obj.type == 1) {
        this.onHotspotGoScene({
          sceneId,
          toSceneId,
          hotspotId
        });
      }
      if (obj.type == 4) {
        this.videoUrl = "//" + obj.videoUrl;
        this.show = true;
      }
      if (obj.type == 5) {
        this.htmlContent = obj.content;
        this.showHtml = true;
      }
    }
  },
  data() {
    return {
      actions: [{ name: "张经理：13305756004" }, { name: "丁经理：13516755798" }],
      showPhone: false,

      //       20230610二、工程实体检测监管系统.mp4
      // 20230610九、在线视频监控系统.mp4
      // 20230610十、扬尘在线监测系统系统.mp4
      // 20230613八、超危工程管理系统.mp4
      // 20230613六、起重机械管理系统.mp4
      // 20230613七、实名制考勤管理系统.mp4
      // 20230613三、预拌混凝土质量监管系统.mp4
      // 20230613四、安心收房惠民服务应用.mp4
      // 20230613五、安责险监管系统.mp4
      // 20230613一、工程质量检测监管系统.mp4
      videoList: [],
      showVideos: false,
      videoUrl: "",
      htmlContent: "",
      project: {},
      projectScenes: [],
      show: false,
      showSide: false,
      showHtml: false,
      showProject: false,
      showMeeting: false,
      showcg:false,
      showbd:false,
      showsc:false
    };
  }
};
</script>
<style lang="scss" scoped>
.video_list_wrap {
  height: 100%;
  box-sizing: border-box;
  background: linear-gradient(180deg,
      rgba(89, 173, 248, 0.24) 0%,
      rgba(255, 255, 255, 0) 100%);
  padding: 25px 18px;

  .video_title {
    font-size: 20px;
    font-weight: 700;
    color: rgba(51, 51, 51, 1);
  }

  .video_sec_title {
    font-size: 18px;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
    margin: 7px 0 20px;
  }

  .video_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .video_item {
      width: 48%;
      padding: 10px 50px 10px 10px;
      box-sizing: border-box;
      border-radius: 10px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 8px rgba(0, 45, 97, 0.1);
      margin-bottom: 10px;
      font-size: 15px;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 1.5em;
      position: relative;

      .video_img {
        background-image: url("../../../assets/分组 1.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 78px;
        height: 78px;
        position: absolute;
        right: 0;
        bottom: -7px;
        opacity: 0.1;
        // z-index: -1;
      }
    }
  }
}

.side_wrap {
  position: absolute;
  bottom: 350px;
  left: 15px;
  z-index: 1;
  background: #ffffff;
  border-radius: 5px;
  padding: 10px;

  .item {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
    padding-top: 10px;
    font-size: 12px;

    .icon {
      margin-bottom: 5px;
    }
  }

  .item:active {
    background: #f5f5f5;
  }

  .item~.item {
    border-top: 1px dashed #e5e5e5;
  }
}

.html_wrap {
  .item {
    display: flex;
    font-size: 14px;
    margin-bottom: 10px;

    .lab {
      flex: 0 0 80px;
      font-weight: bold;
    }

    .con {
      line-height: 1.5em;
      flex: 1;
    }
  }

  .img {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .title {
    color: #fff;
    background: linear-gradient(90deg, #104184, #1040840c);
    font-size: 18px;
    padding: 5px;
    font-style: italic;
    margin-bottom: 15px;
  }

  .sec_title {
    color: #104184;
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 15px;
    font-weight: bold;
    line-height: 1.5em;
  }
}

.meeting_wrap {
  .lab {
    flex: 0 0 110px !important;
  }
}

.pano-container {
  // background: url('../../../assets/cloud.png') no-repeat;
  // background-size: 100% 100%;
  height: 100vh;
  widows: 100vw;
  position: relative;

  .webapp-pano-view-container {
    height: 100vh;
    widows: 100vw;
  }
}

.html_wrap {
  width: 90vw;
  height: 50vh;
  background: #fff;
  overflow: auto;
  padding: 10px;
  border-radius: 5px;

  img {
    max-width: 100%;
  }
}
</style>
